import { render, staticRenderFns } from "./index.vue?vue&type=template&id=dd555f42&lang=pug"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/workspaces/wuwow-junior-vue/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('dd555f42')) {
      api.createRecord('dd555f42', component.options)
    } else {
      api.reload('dd555f42', component.options)
    }
    module.hot.accept("./index.vue?vue&type=template&id=dd555f42&lang=pug", function () {
      api.rerender('dd555f42', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/memberClassRecords/viewAnalysis/comment/index.vue"
export default component.exports