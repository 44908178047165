var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "container bg-white rounded-bottom",
    },
    [
      _c(
        "div",
        {
          staticClass: "row",
          staticStyle: {
            "background-color": "#f3f1ed",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "col",
            },
            [
              !_vm.isLoading
                ? _c("radar-chart", {
                    ref: "chart",
                    staticClass: "my-5",
                    attrs: {
                      "chart-data": _vm.analysis,
                      options: _vm.options,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "row",
        },
        [
          _c(
            "div",
            {
              staticClass: "col bg-blue rounded-top py-2",
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex justify-content-center",
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "text-white h3",
                    },
                    [_vm._v(_vm._s(_vm.material))]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "row border-dark py-4",
        },
        [
          _c(
            "div",
            {
              staticClass: "col-md-3 d-flex justify-content-center",
            },
            [
              _c("font-awesome-icon", {
                staticClass: "mr-2",
                attrs: {
                  icon: "calendar-minus",
                  size: "lg",
                },
              }),
              _vm._v(_vm._s(_vm.createdAt)),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-md-3 d-flex justify-content-center",
            },
            [
              _c("font-awesome-icon", {
                staticClass: "mr-2",
                attrs: {
                  icon: "user",
                  size: "lg",
                },
              }),
              _vm._v(_vm._s(_vm.name.consultant)),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-md-3 d-flex justify-content-center",
            },
            [
              _c("font-awesome-icon", {
                staticClass: "mr-2",
                attrs: {
                  icon: "users",
                  size: "lg",
                },
              }),
              _vm._v(_vm._s(_vm.name.vip)),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "col-md-3 d-flex justify-content-center",
            },
            [
              _c("font-awesome-icon", {
                staticClass: "mr-2",
                attrs: {
                  icon: "chart-line",
                  size: "lg",
                },
              }),
              _vm._v("Lv. " + _vm._s(_vm.averageLevel)),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "row",
        },
        [
          _c(
            "div",
            {
              staticClass: "col",
            },
            [
              _c(
                "div",
                {
                  staticClass: "accordion",
                  attrs: {
                    role: "tablist",
                  },
                },
                [
                  _c("comment", {
                    attrs: {
                      title: "Vocabulary",
                      level: _vm.level.vocabulary,
                      comment: {
                        customize: _vm.comment.vocabulary,
                      },
                    },
                  }),
                  _c("comment", {
                    attrs: {
                      title: "Listening",
                      level: _vm.level.listening,
                      comment: {
                        customize: _vm.comment.listening,
                      },
                    },
                  }),
                  _c("comment", {
                    attrs: {
                      title: "Grammar",
                      level: _vm.level.grammar,
                      comment: {
                        customize: _vm.comment.grammar,
                      },
                    },
                  }),
                  _c("comment", {
                    attrs: {
                      title: "Speaking",
                      level: _vm.level.speaking,
                      comment: {
                        customize: _vm.comment.speaking,
                      },
                    },
                  }),
                  _c("comment", {
                    attrs: {
                      title: "Reading",
                      level: _vm.level.reading,
                      comment: {
                        customize: _vm.comment.reading,
                      },
                    },
                  }),
                  _c("comment", {
                    attrs: {
                      title: "Pronunciation",
                      level: _vm.level.pronunciation,
                      comment: {
                        customize: _vm.comment.pronunciation,
                      },
                    },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }